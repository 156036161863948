import React from "react";
import Image from "../../image";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { VirtualSampleDropdownState } from "../../../helpers/constants";
import { GQLImage } from "../../../types";

type ProductImageProps = {
  productState: VirtualSampleDropdownState;
  defaultImage: GQLImage;
  width?: string;
};

/*
 * productState -> React state obj containing the currently selected product
 * defaultImage -> The default image of the currently selected product
 * width -> A CSS-based width for the image to take up. Height will be adjust automatically
 */
const ProductImage = (props: ProductImageProps): JSX.Element => {
  const UseStyles = makeStyles({
    header: {
      backgroundColor: "#2b2b2b",
      color: "#ffffff",
      padding: "0.5rem 1rem 0.5rem 1rem",
    },

    productImage: {
      textAlign: "center",
      marginTop: "0.5rem",

      "& img": {
        width: props.width ?? "100%",
      },
    },
  });

  const Classes = UseStyles();

  return (
    <Grid item xs={12} md={6}>
      <div className={Classes.header}>
        <Typography variant="button">Product Image</Typography>
      </div>
      <div className={Classes.productImage}>
        {props.productState.valueAsText.length > 0 ? (
          <Image
            url={props.defaultImage.url}
            altText={props.defaultImage.altText}
          />
        ) : (
          <Typography variant="subtitle1">
            Please select an product above to view the image preview.
          </Typography>
        )}
      </div>
    </Grid>
  );
};

export default ProductImage;
