import { Grid, Step, StepLabel, Stepper } from "@material-ui/core";
import React from "react";

type ProgressStepperProps = {
  steps: string[];
  activeStep: number;
};

/*
 * steps -> Array of text labels to be shown on each step
 * activeStep -> The current position in the progress stepper
 */
const ProgressStepper = (props: ProgressStepperProps): JSX.Element => {
  return (
    <Grid item xs={12}>
      <Stepper activeStep={props.activeStep} alternativeLabel>
        {props.steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
};

export default ProgressStepper;
