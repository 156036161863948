/*
 * Ensures an email address is properly formatted
 * - email -> A questionable email address to validate
 */
export const ValidateEmail = (email: string): boolean => {
  const Regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return Regex.test(String(email).toLowerCase());
};

/*
 * Ensures a phone number is properly formatted
 * - phoneNum -> A questionable phone number to validate
 */
export const ValidatePhone = (phoneNum: string): boolean => {
  const Regex = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;
  return Regex.test(phoneNum);
};
