import React from "react";
import ProductImage from "./request-form/product-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { DropzoneArea } from "material-ui-dropzone";
import {
  VirtualSampleDropdownState,
  VirtualSampleFormState,
} from "../../helpers/constants";
import { ValidateEmail, ValidatePhone } from "../../helpers/validation";
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";

const UseStyles = makeStyles({
  header: {
    backgroundColor: "#2b2b2b",
    color: "#ffffff",
    padding: "0.5rem 1rem 0.5rem 1rem",
  },

  productDetails: {
    margin: "0.5rem 0 0.5rem 0",

    "& .MuiTypography-body1": {
      margin: "0 0.5rem 0 0.5rem",
    },
  },

  contactInfo: {
    "& .MuiFormControl-root": {
      width: "100%",
      padding: "0 0.5rem 0 0.5rem",

      "& .MuiFormLabel-root": {
        paddingLeft: "0.5rem",
      },
    },
  },

  uploadLogo: {
    marginTop: "1rem",

    "& .MuiDropzoneArea-root": {
      minHeight: "5rem",
      height: "5rem",
      width: "65%",
      margin: "1rem auto 0 auto",
    },
    "& .MuiDropzoneArea-text": {
      fontSize: "1rem",
      margin: ".5rem 0 .25rem 0",
    },
    "& .MuiDropzoneArea-icon": {
      height: "40px",
      width: "40px",
    },
    "& .MuiDropzonePreviewList-imageContainer": {
      margin: ".25rem auto 0 auto",
    },
  },

  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },

  submitButton: {
    textAlign: "center",
    marginTop: "1rem",

    "& button": {
      backgroundColor: "#AFBD21",

      "&:hover": {
        backgroundColor: "#AFBD21",
      },
    },
  },

  formError: {
    color: "red",

    "&.MuiTypography-body1": {
      margin: "0 0.5rem 0 0.5rem",
    },
  },
});

type RequestFormProps = {
  productState: VirtualSampleDropdownState;
  formState: VirtualSampleFormState;
  onFormChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    validateForm: (formState: VirtualSampleFormState) => boolean
  ) => void;
  onImageStateChange: (files: File[]) => void;
};

/* Validates the customers contact information */
const ValidateForm = (formState: VirtualSampleFormState): boolean => {
  const NameIsValid = formState.name.length > 0;
  const EmailIsValid =
    formState.email.length > 0 && ValidateEmail(formState.email);
  const CompanyIsValid = formState.company.length > 0;
  const AsiIsValid = formState.asiNumber.length > 0;
  const PhoneNumIsValid =
    formState.phoneNumber.length > 0 && ValidatePhone(formState.phoneNumber);

  return (
    NameIsValid &&
    EmailIsValid &&
    CompanyIsValid &&
    AsiIsValid &&
    PhoneNumIsValid
  );
};

/* */
const RequestForm = (props: RequestFormProps): JSX.Element => {
  const Classes = UseStyles();
  const Breakpoints = useBreakpoint();
  const MatchingProducts = props.productState.options.filter(
    (option) => option.id === props.productState.value
  );
  const DefaultImage = MatchingProducts[0]?.defaultImages[0];

  return (
    <>
      {Breakpoints.md ? (
        <ProductImage
          productState={props.productState}
          defaultImage={DefaultImage}
          width={"65%"}
        />
      ) : null}
      <Grid item xs={12} md={6}>
        <form onSubmit={(event) => props.onSubmit(event, ValidateForm)}>
          <div className={Classes.header}>
            <Typography variant="button">Request Form</Typography>
          </div>
          <div className={Classes.productDetails}>
            <Typography variant="h5">Product Details</Typography>
            <Typography variant="body1">
              {props.productState.valueAsText.length > 0
                ? props.productState.valueAsText
                : "Please select a product first."}
            </Typography>
          </div>
          <div className={Classes.contactInfo}>
            <Typography variant="h5">Contact Information</Typography>
            {!props.formState.formIsValid && (
              <Typography variant="body1" className={Classes.formError}>
                There are still some errors in your contact information. Please
                check your email and phone number and then try again.
              </Typography>
            )}
            <TextField
              required
              name="name"
              label="Name"
              disabled={!props.formState.hasValidSelection}
              value={props.formState.name}
              onChange={props.onFormChange}
            />
            <TextField
              required
              name="email"
              label="Email"
              disabled={!props.formState.hasValidSelection}
              value={props.formState.email}
              onChange={props.onFormChange}
            />
            <TextField
              required
              name="company"
              label="Company"
              disabled={!props.formState.hasValidSelection}
              value={props.formState.company}
              onChange={props.onFormChange}
            />
            <TextField
              required
              name="asiNumber"
              label="ASI/PPAI Number"
              disabled={!props.formState.hasValidSelection}
              value={props.formState.asiNumber}
              onChange={props.onFormChange}
            />
            <TextField
              required
              name="phoneNumber"
              label="Phone Number"
              disabled={!props.formState.hasValidSelection}
              value={props.formState.phoneNumber}
              onChange={props.onFormChange}
            />
            <TextField
              multiline
              name="designNotes"
              label="Design Notes"
              disabled={!props.formState.hasValidSelection}
              value={props.formState.designNotes}
              onChange={props.onFormChange}
            />
          </div>
          <div className={Classes.uploadLogo}>
            <Typography variant="h5">Upload Logo</Typography>
            <DropzoneArea
              onChange={props.onImageStateChange}
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview
              previewGridProps={{ container: { spacing: 1, direction: "row" } }}
              previewChipProps={{ classes: { root: Classes.previewChip } }}
              previewText={""}
              acceptedFiles={["image/*"]}
              filesLimit={1}
            />
          </div>
          <div className={Classes.submitButton}>
            <Button variant="contained" type="submit">
              Submit Request
            </Button>
          </div>
        </form>
      </Grid>
      {Breakpoints.md ? null : (
        <ProductImage
          productState={props.productState}
          defaultImage={DefaultImage}
          width={"75%"}
        />
      )}
    </>
  );
};

export default RequestForm;
