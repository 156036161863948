import React from "react";
import {
  VirtualSampleDropdownState,
  Options as Constants,
} from "../../helpers/constants";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

type SelectMenuProps = {
  state: VirtualSampleDropdownState;
  onChange: (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    child: React.ReactNode,
    setState: React.Dispatch<React.SetStateAction<VirtualSampleDropdownState>>
  ) => void;
  value?: string;
  setState: (value: React.SetStateAction<VirtualSampleDropdownState>) => void;
};

const UseStyles = makeStyles({
  formControl: {
    width: "100%",
    marginBottom: "0.35rem",
  },

  select: {
    "&:before": {
      borderColor: "lightgrey",
    },
    "&:after": {
      borderColor: "lightgrey",
    },
  },

  selectIcon: {
    fill: "lightgrey",
  },

  helperText: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
});

/*
 * state -> React state obj containing the current select menu
 * onChange -> React event handler for dropdown menu selections
 * value -> This is either the ID of a brand folder of a product
 * setState -> Updates the React state variable "state"
 */
const SelectMenu = (props: SelectMenuProps): JSX.Element => {
  const Classes = UseStyles();

  return (
    <Grid item xs={12} md={6}>
      <FormControl className={Classes.formControl}>
        <InputLabel focused={false}>{Constants.ChooseOptionText}</InputLabel>
        <Select
          required={props.state.required}
          disabled={props.state.disabled}
          onChange={(event, child) =>
            props.onChange(event, child, props.setState)
          }
          value={props.state.value}
          className={Classes.select}
          inputProps={{ classes: { icon: Classes.selectIcon } }}
        >
          {props.state.options.map((option, index) => (
            <MenuItem key={index} value={option.id}>
              <Typography noWrap={true}>{option.name}</Typography>
            </MenuItem>
          ))}
        </Select>
        <FormHelperText className={Classes.helperText}>
          {props.state.label}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
};

export default SelectMenu;
